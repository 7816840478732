import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";


const calculateTime = (secs) => {
  const minutes = Math.floor(secs / 60);
  const seconds = Math.floor(secs % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${minutes}:${returnedSeconds}`;
}

const AudioPlayer = ({ src, label }) => {
  const audioRef = useRef();
  const seekRef = useRef();
  const [duration, setDuration] = useState("");
  const [seekMax, setSeekMax] = useState(100);
  const [currentTime, setCurrentTime] = useState(undefined);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio.readyState > 0) {
      setDuration(calculateTime(audio.duration));
      //setCurrentTime(calculateTime(audio.duration));
    }
    else {
      audio.addEventListener('loadedmetadata', () => {
        setDuration(calculateTime(audio.duration));
        //setCurrentTime(calculateTime(audio.duration));
      });
    }

    audio.addEventListener("timeupdate", () => {
      setCurrentTime(calculateTime(audio.duration - audio.currentTime));
    })
    
    audio.addEventListener("play", () => {
      setIsPlaying(true);
    });
    audio.addEventListener("pause", () => {
      setIsPlaying(false);
    });

  },[]);

  const togglePlay = () => {
    const audio = audioRef.current;
    if ( audio.paused ) {
      audio.play();
    }
    else {
      audio.pause();

    }
  }


  const playerRef = useRef();
  const [visible,setVisible] = useState(true);
  
  useEffect(() => {
    if ( ! playerRef.current ) return;

    const player = playerRef.current;
    
    let observer;
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: [0,1]
    };
    observer = new IntersectionObserver(( entries ) => {
      if ( entries[0].intersectionRatio < 1 ) {
        setVisible(false);
      }
      else {
        setVisible(true)
      }
    }, options);
    observer.observe( player );

    return () => observer.disconnect();
    
  }, []);


  return (
    <>
      <StyledAudioPlayer onClick={togglePlay} ref={playerRef} className={! visible ? "is-not-visible" : undefined}>
        <audio ref={audioRef} src={src} preload="meta" />
        {isPlaying ? <StyledPause /> : <StyledPlay />}
        {label && <p>{label}</p>}
        <span>{currentTime && currentTime}</span>
      </StyledAudioPlayer>
      <StyledAudioFixed  onClick={togglePlay} className={! visible ? "is-visible" : undefined}>
        {isPlaying ? <StyledPause /> : <StyledPlay />}
        {label && <p>{label}</p>}
        <span>{currentTime && currentTime}</span>
      </StyledAudioFixed>
    </>
  )
}
export default AudioPlayer;

const StyledAudioPlayer = styled.button`
  position: relative;
  display: grid;
  grid-template: 1fr / 1.25em auto 1fr;
  grid-gap: 1em;
  align-items: center;
  user-select: none;
  font-size: var(--text-size);
  margin: 1.5em 0;
  cursor: pointer;
  .can-hover &:hover,
  &:active {
    p,
    > div {
      opacity: 0.5;
    }

  }
  p {
    margin: 0;
  }
  span {
    font-weight: 600;
    font-size: 0.875em;
    margin-top: 0.125em;
    opacity: 0.5;
  }
`;

const StyledAudioFixed = styled(StyledAudioPlayer)`
  position: fixed;
  bottom: 0;
  z-index: 100;
  background: var(--color-background);
  padding: 1.5em;
  font-size: 1em;
  left: 0;
  margin: 0;
  transform: translate3d(-100%,0,0);
  transition: transform 0.6s ease;
  &.is-visible {
    transform: translate3d(0,0,0);
  }
  @media ( max-width: 48em ) {
    right: 0;
  }
`;


const StyledPlay = styled.div`
  height: 1em;
  width: 0.875em;
  font-size: 1.25em;
  background-color: currentColor;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  vertical-align: middle;
  display: inline-block;
`;

const StyledPause = styled.div`
  height: 1em;
  width: 0.875em;
  font-size: 1.25em;
  background-color: currentColor;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 0.25em;
    margin-left: -0.125em;
    background-color: var(--color-background);
  }
`;