import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import StyledText from "../components/StyledText";
import Arrow from "../components/Arrow";
import MuralThumb from "../components/MuralThumb";
import ReactMarkdown from "react-markdown";
import Meta from "../components/Meta";
import ExploreIcon from "../components/ExploreIcon";
import ArrowRight from "../components/ArrowRight";
import ArrowDown from "../components/ArrowDown";
import AudioPlayer from "../components/AudioPlayer";
import { getMuralSlug } from "../helpers/getMuralSlug";

// the-outlaw-ocean-mural-project__at-the-intersection-of-public-art-and-journalism
const IndexPage = ({ data }) => {

  //const [shuffledMurals, setShuffledMurals] = useState(data.directus.murals.sort((a, b) => 0.5 - Math.random()));
  const projectSettings = data.directus.mural_project;
  const shuffledMurals = data.directus.murals.sort((a, b) => 0.5 - Math.random());

  const firstMural = data.directus.murals[0];

  return (
    <>
      <Meta
        title="The Outlaw Ocean Mural Project"
        image="/the-outlaw-ocean-mural-project-cover.jpg"
      />
      <StyledIndex>
        <StyledText>
          <ReactMarkdown>{projectSettings.home_intro}</ReactMarkdown>
          <AudioPlayer src="/the-outlaw-ocean-mural-project__at-the-intersection-of-public-art-and-journalism.mp3" label="About the project" />
          <br />
          <StyledArrowWrap>
            <ArrowDown />
          </StyledArrowWrap>
        </StyledText>
        <ul>
          {shuffledMurals.map((item, index) => (
            <li key={`index-mural-${index}`}>
              <MuralThumb item={item}  />
            </li>
          ))}
        </ul>
        <StyledOptions>
          <StyledNudge>
            <Link to={`/murals/${getMuralSlug(firstMural)}`}><ArrowRight /> Go to first mural</Link>
            <Link to="/murals" title="Explore all murals"><ExploreIcon /> Explore all murals</Link>
          </StyledNudge>
          <StyledText>
            <p>Murals added monthly</p>
          </StyledText>
        </StyledOptions>
        <StyledText>
          <ReactMarkdown>{projectSettings.home_outro}</ReactMarkdown>
        </StyledText>
      </StyledIndex>
    </>
  );
};

export default IndexPage;

const StyledArrowWrap = styled.span`
  position: relative;
  display: inline-block;
  margin-top: 2em;
  transform: translate3d(-50%,0,0);
`;
const StyledExplore = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2em;
  font-size: var(--text-size);
  span {
    position: relative;
    display: inline-block;
    transform: translate3d(0.45em,0,0) rotate(-90deg);
    transform-origin: left center;
  }
  @media ( min-width: 60em ) {
    margin-top: -3em;
  }
`

const StyledPlay = styled.span`
  height: 1em;
  width: 0.875em;
  background-color: currentColor;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  margin-right: 0.75em;
  vertical-align: middle;
  display: inline-block;
  margin-top: -0.125em;
`

const StyledIndexIntro = styled.div`
  svg {
    margin-left: -0.5em;
  }
  @media ( min-width: 60em ) {
    grid-column: 1 / span 2;
    position: relative;
    display: grid;
    grid-template: auto / 1fr 1fr;
    align-items: end;
    margin-bottom: 6em;
    p:last-of-type {
      margin-bottom: -0.375em;
    }
    svg {
      position: absolute;
      margin-top: 3em;
    }
  }
`

const StyledIndex = styled.div`
  padding: 3em 0;
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 3em 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  @media ( min-width: 60em ) {
    padding: 6em 0;
    grid-template: auto / 1fr 1fr;
    grid-gap: 6em 0;
    > ul {
      grid-column: 1 / span 2;

    }
    ${StyledText} {
      grid-column: 2;
    }
  }
`;


const StyledOptions = styled.div`
  p {
    opacity: 0.5;
  }
  @media ( min-width: 60em ) {
    position: sticky;
    top: 3em;
    align-self: start;
    p {
      position: relative;
      transform: rotate(-90deg) translate3d(-110%,40%,0);
      transform-origin: left center;
      display: inline-block;
      line-height: 1;
    }
  }
`
const StyledNudge = styled.nav`
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 1em;
  margin-bottom: 1.5em;
  a {
    display: grid;
    grid-template: 1fr / 3em 1fr;
    align-items: center;
    font-size: var(--text-size);
    font-weight: var(--text-weight);
    svg,
    div {
      font-size: 1.125em;
    }
    .can-hover &:hover,
    &:active {
      opacity: 0.5;
    }
  }
`

export const query = graphql`
  query {
    directus {
      mural_project {
        home_intro
        home_outro
      }
      murals(
        sort: "-id"
        filter: {
          status: {_eq: "published"}
        }
      ) {
        artists {
          mural_artists_id {
            id
            name
            slug
          }
        }
        country
        image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 2048, quality: 80)
            }
          }
        }
        slug
        override_artist_slug
        override_artist_name
        title
        video_cover_url
      }
    }
  }
`;